import * as R from 'ramda'
import {graphql} from 'gatsby'
import loadable from '@loadable/component'
import React from 'react'

import {investorsPropTypes} from 'helpers/propTypes'
import BarCharts from 'components/AboutUs/Investors/BarCharts'
import CapitalStructureTable from 'components/AboutUs/Investors/CapitalStructureTable'
import ContentSection from 'components/UI/ContentSection'
import EventAndDocuments from 'components/AboutUs/Investors/EventAndDocuments'
import Hero from 'components/UI/Hero'
import Information from 'components/AboutUs/Investors/Information'
import MainCard from 'components/AboutUs/Investors/MainCard'
import PieCharts from 'components/AboutUs/Investors/PieCharts'
import RawText from 'components/UI/RawText'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import ShareHolders from 'components/AboutUs/Investors/ShareHolders'
import StockBlock from 'components/AboutUs/Investors/StockBlock'

import keyrusStockImage from '../images/investors-content-section.png'

const Investors = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const {
    node_locale,
    speechContent,
    speechCeo,
    eventsTitle,
    eventsMoreLabel,
    eventsNextLabel,
    eventsLessLabel,
    eventsItems,
    downloadCta,
    downloadMoreLabel,
    imageOrVideo,
    metaTitle,
    metaDescription,
    pageTitle,
    shortDescription: {shortDescription},
    keyFiguresTitle,
    keyFiguresRevenuesTitle,
    keyFiguresCurrentOperationalResultsTitle,
    keyFiguresHeadcountTitle,
    keyFiguresData,
    keyFiguresBreakdownTitle,
    keyFiguresBreakdownMarketSegmentTitle,
    keyFiguresBreakdownLegend,
    keyFiguresBreakdownIndustryTitle,
    keyFiguresBreakdownGeographicalTitle,
    capitalStructureData,
    capitalStructureTableHeaders,
    contentSections,
    investorsRelationsContactsEmail,
    investorsRelationsContactsPhone,
    investorsRelationsContactsTitle,
    investorsRelationsHeader,
    investorsRelationsInfosGeneralMeetings,
    investorsRelationsInfosSecDepartment,
    investorsRelationsInfosTitle,
    investorsRelationsMyKeyrusLink,
    investorsRelationsMyKeyrusLinkCta,
    investorsRelationsMyKeyrusLinkCtaLink,
    financialInformationEvents,
    keyrusStockTitle,
    keyrusStockBlock1,
    keyrusStockBlock2,
    keyrusStockBlock3,
    financialNews,
    financialPublications,
    surtitle,
    capitalStructureShareHolders,
  } = R.pathOr(null, ['contentfulInvestors'], data)

  const richTextDocuments = R.pathOr(null, ['richTextDocuments', 'nodes'], data)

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div className="decorationWrapper">
        <Decorations>
          <Decoration webSiteName="keyrus" color="blue" top={52} />
          <Decoration webSiteName="keyrus" color="orange" right={0} top={60} />
          <Decoration webSiteName="keyrus" color="red" right={0} bottom={0} />
          <Decoration webSiteName="kls" svg="left" left={-80} top={0} />
          <Decoration webSiteName="kls" svg="right" right={0} top={34} />
        </Decorations>
        <Section hasPaddingTop={false} hasVerySmallPadding>
          <Hero
            hat={surtitle}
            title={pageTitle}
            description={shortDescription}
            media={imageOrVideo}
            offsetBottom
            titleType="title"
          />
        </Section>
      </div>
      <Section
        hasGreyBackground
        hasSmallPadding
        hasPaddingBottom={false}
        hasPaddingTop={false}
      >
        <MainCard description={speechContent} ceo={speechCeo} />
        <EventAndDocuments
          eventsTitle={eventsTitle}
          eventsMoreLabel={eventsMoreLabel}
          eventsNextLabel={eventsNextLabel}
          eventsLessLabel={eventsLessLabel}
          downloadCta={downloadCta}
          downloadMoreLabel={downloadMoreLabel}
          eventsItems={eventsItems}
          nodeLocale={node_locale}
          financialNews={financialNews}
          financialPublications={financialPublications}
          countryData={countryData}
          pageContext={pageContext}
        />
      </Section>
      <Section hasSmallPadding hasPaddingBottom={false}>
        <BarCharts
          keyFiguresTitle={keyFiguresTitle}
          keyFiguresRevenuesTitle={keyFiguresRevenuesTitle}
          keyFiguresCurrentOperationalResultsTitle={
            keyFiguresCurrentOperationalResultsTitle
          }
          keyFiguresHeadcountTitle={keyFiguresHeadcountTitle}
          keyFiguresData={keyFiguresData}
          keyFiguresBreakdownTitle={keyFiguresBreakdownTitle}
          keyFiguresBreakdownMarketSegmentTitle={
            keyFiguresBreakdownMarketSegmentTitle
          }
          keyFiguresBreakdownLegend={keyFiguresBreakdownLegend}
        />
      </Section>
      <Section hasSmallPaddings hasPaddingTop={false}>
        <PieCharts
          keyFiguresBreakdownTitle={keyFiguresBreakdownTitle}
          keyFiguresBreakdownMarketSegmentTitle={
            keyFiguresBreakdownMarketSegmentTitle
          }
          keyFiguresBreakdownLegend={keyFiguresBreakdownLegend}
          keyFiguresBreakdownIndustryTitle={keyFiguresBreakdownIndustryTitle}
          keyFiguresBreakdownGeographicalTitle={
            keyFiguresBreakdownGeographicalTitle
          }
          keyFiguresData={keyFiguresData.breakdown}
        />
      </Section>
      <Section hasGreyBackground hasVerySmallPadding>
        <ContentSection
          showContent={contentSections[0].showContentSection}
          title={contentSections[0].title}
          callToAction={contentSections[0].callToAction}
          image={contentSections[0].image}
          variant={contentSections[0].variation}
          description={contentSections[0].description}
          link={contentSections[0].link}
          isOpenInNewWindow={contentSections[0].isOpenInNewWindow}
          typeOfLink={contentSections[0].typeOfLink}
          countryData={countryData}
        />
      </Section>
      <Section hasVerySmallPadding>
        <ShareHolders
          investorsRelationsContactsEmail={investorsRelationsContactsEmail}
          investorsRelationsContactsPhone={investorsRelationsContactsPhone}
          investorsRelationsContactsTitle={investorsRelationsContactsTitle}
          investorsRelationsHeader={investorsRelationsHeader}
          investorsRelationsMyKeyrusLink={investorsRelationsMyKeyrusLink}
          investorsRelationsMyKeyrusLinkCta={investorsRelationsMyKeyrusLinkCta}
          investorsRelationsMyKeyrusLinkCtaLink={
            investorsRelationsMyKeyrusLinkCtaLink
          }
          countryData={countryData}
        />
      </Section>
      <Section
        hasSmallPaddings
        hasGreyBackground
        hasPaddingTop={false}
        hasPaddingBottom={false}
      >
        <Information
          investorsRelationsInfosGeneralMeetings={
            investorsRelationsInfosGeneralMeetings
          }
          investorsRelationsInfosSecDepartment={
            investorsRelationsInfosSecDepartment
          }
          investorsRelationsInfosTitle={investorsRelationsInfosTitle}
        />
      </Section>
      <Section hasVerySmallPadding>
        <RawText
          text={financialInformationEvents}
          richTextDocuments={richTextDocuments}
        />
      </Section>
      <Section hasGreyBackground hasSmallPadding>
        <CapitalStructureTable
          capitalStructureTableHeaders={capitalStructureTableHeaders}
          capitalStructureData={capitalStructureData}
          capitalStructureShareHolders={capitalStructureShareHolders}
        />
      </Section>
      <Section
        hasGreyBackground
        hasSmallPadding
        hasPaddingTop={false}
        hasPaddingBottom={false}
      >
        <StockBlock
          keyrusStockTitle={keyrusStockTitle}
          keyrusStockImage={keyrusStockImage}
          keyrusStockBlock1={keyrusStockBlock1}
          keyrusStockBlock2={keyrusStockBlock2}
          keyrusStockBlock3={keyrusStockBlock3}
        />
      </Section>
    </>
  )
}

Investors.propTypes = investorsPropTypes

Investors.defaultProps = {}

export default Investors

export const pageQuery = graphql`
  query templateInvestors(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulInvestors(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      node_locale
      pageTitle
      metaTitle
      metaDescription {
        metaDescription
      }
      shortDescription {
        shortDescription
      }
      speechContent {
        raw
      }
      speechCeo {
        id
        photo {
          file {
            url
          }
        }
        jobTitle
        linkedInProfile
        name
      }
      imageOrVideo {
        file {
          url
          contentType
        }
      }
      eventsTitle
      eventsMoreLabel
      eventsLessLabel
      eventsNextLabel
      eventsItems {
        name
        date
        formattedDate: date(formatString: "D MMMM YYYY", locale: $nodeLocale)
      }
      downloadMoreLabel
      downloadCta
      capitalStructureTableHeaders {
        raw
      }
      capitalStructureData {
        raw
      }
      capitalStructureShareHolders {
        raw
      }
      callToAction
      keyFiguresTitle
      keyFiguresRevenuesTitle
      keyFiguresCurrentOperationalResultsTitle
      keyFiguresHeadcountTitle
      keyFiguresData {
        breakdown {
          byGeographicArea {
            name
            valueLastYear
            valueThisYear
            color
          }
          byIndustry {
            name
            valueLastYear
            valueThisYear
            color
          }
          byMarketSegment {
            name
            valueLastYear
            valueThisYear
            color
          }
        }
        consolidatedRevenues {
          consolidatedRevenue
          year
        }
        headcounts {
          headcount
          year
        }
        operatingResult {
          operatingResult
          year
        }
      }
      keyFiguresBreakdownTitle
      keyFiguresBreakdownMarketSegmentTitle
      keyFiguresBreakdownLegend {
        raw
      }
      keyFiguresBreakdownIndustryTitle
      keyFiguresBreakdownGeographicalTitle
      contentSections {
        title
        callToAction
        image {
          file {
            url
            contentType
          }
        }
        description {
          raw
        }
        link
        variation
        isOpenInNewWindow
        typeOfLink
      }
      financialInformationEvents {
        raw
      }
      investorsRelationsContactsEmail
      investorsRelationsContactsPhone {
        raw
      }
      investorsRelationsContactsTitle
      investorsRelationsHeader {
        raw
      }
      investorsRelationsInfosGeneralMeetings {
        raw
      }
      investorsRelationsInfosSecDepartment {
        raw
      }
      investorsRelationsInfosTitle
      investorsRelationsMyKeyrusLink {
        raw
      }
      investorsRelationsMyKeyrusLinkCta
      investorsRelationsMyKeyrusLinkCtaLink
      keyrusStockTitle
      surtitle
      keyrusStockBlock3 {
        raw
      }
      keyrusStockBlock2 {
        raw
      }
      keyrusStockBlock1 {
        raw
      }
      keyFiguresBreakdownIndustryTitle
      keyFiguresBreakdownGeographicalTitle
      financialNews {
        pageTitle
        documents {
          name
          file {
            file {
              contentType
              url
              fileName
            }
          }
          publicationDate(formatString: "YYYY-MM-DD")
        }
        slug
      }
      financialPublications {
        pageTitle
        documents {
          name
          file {
            file {
              contentType
              url
              fileName
            }
          }
          publicationDate(formatString: "YYYY-MM-DD")
        }
        slug
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    richTextDocuments: allContentfulDocument(
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        contentful_id
        name
      }
    }
  }
`
